import axios from "axios";

import { Authentication } from "./index";
import config from "../config";

import store from "../store/store";
import { ERRORS } from "../constants/errors";
import { UserFriendlyError } from "../errors/UserFriendlyErrors";
import { throwErrorBasedOnStatus } from "../errors/ErrorHandler";

axios.defaults.timeout = 10000;
axios.defaults.timeoutErrorMessage = ERRORS.serviceUnavailable;

const motoTourAPI = axios.create({
  baseURL: config.API_URL,
  headers: {
    "Accept-Version": "2.0.0",
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const hereAPI = axios.create({
  baseURL: config.HERE_API_URL,
  headers: {
    "Content-Type": "application/binary",
  },
  params: {
    app_id: config.HERE_APP_ID,
    app_code: config.HERE_APP_CODE,
  },
});

motoTourAPI.interceptors.request.use(
  async (config) => {
    if (config.withCredentials) {
      config.withCredentials = false;

      const credentials = store.getState().credentials.mainApi;

      const accessToken =
        credentials?.token?.accessToken || localStorage.getItem("accessToken");

      if (
        credentials?.token &&
        new Date(credentials.token.expireDate) < new Date()
      ) {
        await Authentication.refreshToken({
          body: { refreshToken: credentials.token.refreshToken },
        });
      }

      config.headers = {
        ...config.headers,
        Authorization: accessToken,
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

motoTourAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error instanceof UserFriendlyError || error.code === "ECONNABORTED")
      throw error;
    throwErrorBasedOnStatus(error.response.status);
  }
);

hereAPI.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.code === "ECONNABORTED") throw error;
    throwErrorBasedOnStatus(error.response.status);
  }
);

const { get, post, patch, put, delete: destroy } = motoTourAPI;
export { get, post, patch, put, destroy, hereAPI };
