/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import styles from "./RouteList.module.scss";
import useApi from "../../hooks/useApi";
import store from "../../store/store";
import { resetRoutes } from "../../store/state/routes";
import { getAllRoutes } from "../../api/actions/routesActions";
import { RootState } from "../../store/rootReducer";

import Loader from "../loader/Loader";
import RouteListItem from "./RouteListItem";

function RouteList() {
  const routes = useSelector((state: RootState) => state.routes);
  const filters = useSelector((state: RootState) => state.filters.filters);
  const [page, setPage] = useState(routes.page || 0);
  const mounted = useRef(false);

  const limit = 20;
  const hasMoreRoutes = routes.routes.length < routes.routesCount;

  const getRoutes = useApi({
    action: () => getAllRoutes(limit, page, filters, !!routes.private),
  });

  useEffect(() => {
    return () => {
      store.dispatch(resetRoutes());
    };
  }, []);

  useEffect(() => {
    getRoutes();
  }, [page, routes.private]);

  useEffect(() => {
    setPage(0);
  }, [routes.private]);

  useEffect(() => {
    if (mounted.current) {
      store.dispatch(resetRoutes());
      if (!page) {
        getRoutes();
        return;
      }
      setPage(0);
    }
    mounted.current = true;
  }, [filters]);

  function loadMoreRoutes() {
    setPage(page + 1);
  }

  return (
    <div className={styles.routeList} id="routeList">
      <header className={styles.routeListHeader}>
        <p className={`mediumTitle`}>{routes.routesCount} Tours</p>
      </header>
      <div className={styles.infiniteScroll} id="idRouteList">
        <InfiniteScroll
          scrollableTarget="idRouteList"
          className={styles.infiniteScrollInside}
          dataLength={routes.routes.length}
          hasMore={hasMoreRoutes}
          next={loadMoreRoutes}
          loader={<Loader />}
        >
          {routes.routes.map((route, index) => (
            <RouteListItem route={route} key={index} />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
}

export default RouteList;
