import React, { ComponentProps } from "react";
import MSlider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core/styles";

const CustomSlider = withStyles({
  root: {
    color: "#ff4507",
    height: 3,
    padding: "10px 0",
    width: "210px",
  },
  thumb: {
    height: 14,
    width: 14,
    backgroundColor: "#fff",
    boxShadow: "0 3px 6px rgba(0, 0, 0, 0.08)",
    "&:hover": {
      boxShadow: "0 2px 3px 1px #ccc",
    },
    "&:focus, &$active": {
      boxShadow: "0 3px 6px rgba(0, 0, 0, 0.08)",
    },
    "& .dot": {
      height: 6,
      width: 6,
      backgroundColor: "#ff4507",
      borderRadius: "50%",
    },
  },
  active: {},
  track: {
    height: 3,
    borderRadius: 4,
  },
  rail: {
    color: "#f0f0f0",
    opacity: 1,
    height: 3,
  },
})(MSlider);

function customThumb(props: ComponentProps<"span">) {
  return (
    <span {...props}>
      <span className="dot" />
    </span>
  );
}

interface Props {
  value: number[];
  setValue: (value: number[]) => void;
  commitValue: (value: number[]) => void;
  range: number[];
  steps?: number;
}

function Slider({ value, setValue, commitValue, steps, range }: Props) {
  return (
    <CustomSlider
      ThumbComponent={customThumb}
      min={range[0]}
      max={range[1]}
      step={steps}
      value={[...value]}
      onChange={(_, newValue) => setValue(newValue as number[])}
      onChangeCommitted={(_, newValue) => commitValue(newValue as number[])}
    />
  );
}

export default Slider;
