import { combineReducers } from "@reduxjs/toolkit";

import routes from "./state/routes";
import credentials from "./state/credentials";
import selectedRoute from "./state/selectedRoute";
import previewRoute from "./state/previewRoute";
import filters from "./state/filters";

const rootReducer = combineReducers({
  routes,
  credentials,
  selectedRoute,
  previewRoute,
  filters,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
