import React from "react";

import styles from "./RouteInfoList.module.scss";

import { ListItem } from "../../models/Preview";

interface Props {
  list: ListItem[];
  columns: number;
  gap?: string;
}

function RouteInfoList({ list, columns, gap }: Props) {
  return (
    <div
      className={styles.list}
      style={{
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        gap: `${gap ? gap : "18px 5px"}`,
      }}
    >
      {list.map((listItem, index) => {
        return (
          <div className={styles.listItem} key={index}>
            <div className={styles.imageWrapper}>
              <img src={listItem.icon} alt="icon" />
            </div>
            <span>{listItem.text}</span>
          </div>
        );
      })}
    </div>
  );
}

export default RouteInfoList;
