import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  HPlatform,
  HMap,
  HMapRoute,
  HMapMarkers,
} from "@robinuit/react-here-maps-library";

import config from "../../config";
import store from "../../store/store";
import IHERERouteResponse from "../../models/Here";
import { RootState } from "../../store/rootReducer";
import {
  changeSelectedRoute,
  updateRouteParams,
} from "../../store/state/selectedRoute";
import { IWaypoint } from "../../models/Waypoint";

import EndMarker from "../../assets/icons/endMarker.svg";
import StartMarker from "../../assets/icons/startMarker.svg";
import EditMarker from "../../assets/icons/waypointMarker.svg";
import RouteMarker from "../../assets/icons/routeMarker.svg";
import { formatDistance, formatSeconds } from "../../utils/formatUtils";

function Map() {
  const [center] = useState({ lat: 52.092876, lng: 5.10448 });
  const [zoom] = useState(7);
  const { edit, waypoints } = useSelector(
    (state: RootState) => state.selectedRoute
  );
  const routes = useSelector((state: RootState) => state.routes.routes);
  const routesList = routes.map((route) => ({
    lat: route.trackStart?.coordinates[1]!,
    lng: route.trackStart?.coordinates[0]!,
  }));

  function changeWaypoints(waypoints: IWaypoint[]) {
    store.dispatch(changeSelectedRoute({ waypoints }));
  }

  function updateRoute(response: IHERERouteResponse) {
    store.dispatch(
      updateRouteParams({
        distance: formatDistance(response.summary.distance),
        duration: formatSeconds(response.summary.baseTime),
      })
    );
  }

  return (
    <HPlatform
      app_id={config.HERE_APP_ID}
      app_code={config.HERE_APP_CODE}
      apikey={config.HERE_API_KEY}
      interactive
      useHTTPS
      includeUI
      useLocation
      language="DUT"
    >
      <HMap mapOptions={{ center: center, zoom: zoom }}>
        <HMapRoute
          routeParams={{
            mode: "shortest;car;traffic:disabled",
            waypoints: waypoints,
            representation: "display",
            routeAttributes: "summary",
          }}
          edit={edit}
          returnResponse={updateRoute}
          returnWaypoints={changeWaypoints}
          icons={{
            startIcon: StartMarker,
            endIcon: EndMarker,
            waypointIcon: "none",
            editIcon: EditMarker,
          }}
          lineOptions={{ style: { strokeColor: "#FF4507", lineWidth: 5 } }}
        />
        <HMapMarkers
          points={!edit ? routesList : []}
          icon={RouteMarker}
          setViewBounds={false}
        />
      </HMap>
    </HPlatform>
  );
}

export default Map;
