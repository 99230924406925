import React from "react";

import styles from "./PreviewCard.module.scss";
import Preview from "../../models/Preview";
import { formatImgUrl } from "../../utils/formatUtils";

import Edit from "../../assets/icons/edit.svg";
import Close from "../../assets/icons/close.svg";

import RouteInfoList from "../routeInfoList/RouteInfoList";
import Button from "../navigation/Button";

type Props = {
  preview: Preview;
};

function PreviewCard(props: Props) {
  const preview = props.preview;

  function showMoreInfo() {
    preview.onMoreInfo?.();
  }

  function add() {
    preview.onAdd?.();
  }

  function closePreview() {
    preview.onClose?.();
  }

  function editPreview() {
    preview.onEdit?.();
  }

  return (
    <div className={styles.previewCard}>
      <div className={styles.imageRow}>
        <div className={styles.actions}>
          {preview.onEdit && (
            <img
              src={Edit}
              alt={`Bewerk ${preview.item.type}`}
              onClick={editPreview}
            />
          )}
          {preview.onClose && (
            <img
              src={Close}
              alt={`Sluit ${preview.item.type} preview`}
              onClick={closePreview}
            />
          )}
        </div>
        <img
          className={styles.routeImage}
          src={formatImgUrl(preview.item.image)}
          onError={(e) => (e.currentTarget.src = formatImgUrl(undefined))}
          alt={`Een foto genomen uit de ${preview.item.type}: ${preview.item.title}.`}
        />
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.infoRow}>
          <div className={styles.infoHeading}>
            <h1>{preview.item.title}</h1>
            <h2>{preview.item.subtitle}</h2>
          </div>
          <RouteInfoList list={preview.item.list} columns={2} />
        </div>
        <div className={styles.buttonRow}>
          {preview.item.type === "route" && (
            <div className={styles.right}>
              <Button text="Meer info" type="primary" onClick={showMoreInfo} />
            </div>
          )}
          {preview.item.type === "stop" && (
            <>
              <div className={styles.left}>
                <Button
                  text="Meer info"
                  type="secondary"
                  onClick={showMoreInfo}
                />
              </div>
              <div className={styles.right}>
                <Button text="Toevoegen" type="primary" onClick={add} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PreviewCard;
