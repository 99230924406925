import { IRouteForm } from "./../../models/Route";
import { AxiosResponse } from "axios";

import { HERE, Routes } from "../index";
import { apiService } from "../service";

import store from "../../store/store";
import {
  changeSelectedRoute,
  resetSelectedRoute,
  setSelectedRoute,
} from "../../store/state/selectedRoute";
import {
  resetPreviewRoute,
  setPreviewRoute,
} from "../../store/state/previewRoute";
import { setRoutes } from "../../store/state/routes";

import IRoute from "../../models/Route";
import { IFilter } from "../../models/Filter";
import { ISelectedRoute } from "../../models/Route";
import { APIWaypoint, IWaypoint } from "../../models/Waypoint";

export async function getAllRoutes(
  limit: number,
  page: number,
  filters: IFilter,
  privateRoutes: boolean
): Promise<void> {
  const query = { limit, page, ...filters, private: privateRoutes };

  const response: AxiosResponse = await apiService(Routes.get({ query }), true);
  const routesCount = response.headers["x-count"];

  store.dispatch(setRoutes({ routes: response.data, routesCount, page }));
}

export async function createRoute(route: IRouteForm): Promise<void> {
  const body = new FormData();
  const query = {};

  Object.entries(route).forEach(([key, value]) => {
    if (key === "track") {
      body.append(key, value[0]);
      return;
    }
    if (key === "images") {
      value.forEach((image: File) => {
        body.append(key, image);
      });
      return;
    }
    Object.assign(query, { [key]: value });
  });

  await apiService(Routes.create({ body, query }));
}

export async function updateWaypoints(route: ISelectedRoute) {
  const waypoints = route.waypoints.map((waypoint) => {
    return { lat: waypoint.lat, lon: waypoint.lng };
  });

  await apiService(
    Routes.updateWaypoints(route.route._id!, {
      body: waypoints,
    })
  );
}

export async function updateRoute(
  newValues: { [key: string]: string | File[] },
  id: string
): Promise<void> {
  const body = new FormData();

  Object.entries(newValues).forEach(([key, value]) => {
    if (typeof value === "string" || typeof value === "boolean") {
      body.append(key, value);
    } else if (key === "images") {
      value.forEach((image: File) => {
        body.append(key, image);
      });
    }
  });

  await apiService(Routes.update(id, { body }));
}

export async function getRouteWaypoints(route: IRoute): Promise<void> {
  const routeWaypoints: APIWaypoint[] = await apiService(
    Routes.getWaypoints(route._id!)
  );

  const waypoints: IWaypoint[] = routeWaypoints.map((waypoint) => ({
    lat: waypoint.latMatched,
    lng: waypoint.lonMatched,
  }));

  store.dispatch(setSelectedRoute({ route, waypoints, edit: false }));
  store.dispatch(setPreviewRoute({ route, previewShown: true }));
}

export async function getRouteFromGPX(gpx: File): Promise<void> {
  const response = await apiService(
    HERE.getWaypointsFromGPX({ query: { routeMode: "car" }, body: gpx })
  );

  const routeWaypoints: APIWaypoint[] = response.TracePoints.filter(
    (waypoint: { confidenceValue: number }) => waypoint.confidenceValue === 1
  );

  const waypoints: IWaypoint[] = routeWaypoints.map((waypoint) => ({
    lat: waypoint.latMatched,
    lng: waypoint.lonMatched,
  }));

  store.dispatch(changeSelectedRoute({ waypoints }));
}

export function resetRoute() {
  store.dispatch(resetSelectedRoute());
  store.dispatch(resetPreviewRoute());
}
