import React, { useEffect } from "react";

import styles from "./Routes.module.scss";
import store from "../../../store/store";
import { showFilters } from "../../../store/state/filters";

import ListSwitcher from "../../../components/listSwitcher/ListSwitcher";
import RouteList from "../../../components/routeList/RouteList";

function Routes() {
  useEffect(() => {
    store.dispatch(showFilters(true));

    return () => {
      store.dispatch(showFilters(false));
    };
  }, []);

  return (
    <div className={styles.routes}>
      <ListSwitcher />
      <RouteList />
    </div>
  );
}

export default Routes;
