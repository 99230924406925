import React, { useState } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { useHistory } from "react-router-dom";

import styles from "./WaypointsList.module.scss";
import store from "../../store/store";
import useApi from "../../hooks/useApi";
import {
  resetRoute,
  updateRoute,
  updateWaypoints,
} from "../../api/actions/routesActions";
import { changeSelectedRoute } from "../../store/state/selectedRoute";
import { ISelectedRoute, IUpdateRouteParams } from "../../models/Route";

import WaypointsListElement from "./WaypointsListElement";
import ButtonBox from "../navigation/ButtonBox";
import { formatDifference } from "../../utils/formatUtils";
import { ROUTES } from "../../constants/routes";

interface Props {
  route: ISelectedRoute;
}

function WaypointsList({ route }: Props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const initialValues: IUpdateRouteParams = {
    duration: +route.route.duration || 0,
    distance: +route.route.distance || 0,
  };

  const handleSaveRoute = useApi({
    action: () => {
      updateWaypoints(route);
      return updateRoute(
        formatDifference(initialValues, {
          duration: route.route.duration,
          distance: route.route.distance,
        }),
        route.route._id!
      );
    },
    onStart: () => setLoading(true),
    onError: () => setLoading(false),
    onSuccess: () => {
      resetRoute();
      history.push(
        route.route.private
          ? ROUTES.home.privateRoutes
          : ROUTES.home.publicRoutes
      );
    },
  });

  function onDragEnd(result: DropResult) {
    if (!result.destination) return;
    const { source, destination } = result;
    const copiedItems = Object.assign([], route.waypoints);
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);

    store.dispatch(changeSelectedRoute({ waypoints: copiedItems }));
  }

  return (
    <div className={styles.list}>
      <div className={styles.waypoints}>
        <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
          <Droppable droppableId={"waypointslist"}>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {route.waypoints.map((waypoint, index) => (
                  <Draggable
                    key={index.toString()}
                    draggableId={index.toString()}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          userSelect: "none",
                          ...provided.draggableProps.style,
                        }}
                      >
                        <WaypointsListElement
                          key={index}
                          waypoint={waypoint}
                          isDragging={snapshot.isDragging}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className={styles.submit}>
        <ButtonBox
          text="Route opslaan"
          type="primary"
          onClick={handleSaveRoute}
          loading={loading}
        />
      </div>
    </div>
  );
}

export default WaypointsList;
