import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import styles from "./Login.module.scss";
import { ROUTES } from "../../constants/routes";

import Logo from "../../assets/icons/MotoTour_logo.svg";

import ResetPassword from "./resetPassword/ResetPassword";
import ForgotPassword from "./forgotPassword/ForgotPassword";
import VerifyEmail from "./verifyEmail/VerifyEmail";
import RegisterForm from "./registerForm/RegisterForm";
import LoginForm from "./loginForm/LoginForm";

function Login() {
  return (
    <main className={styles.mainContainer}>
      <div className={styles.imagePanel}>
        <div className={styles.quoteContainer}>
          <div className={styles.quoteInnerContainer}>
            <p className={`futuraText ${styles.quoteText}`}>
              “It wasn’t until I went to college and I got my first motorcycle
              that I understood the thrill of speed.”
            </p>
            <p className={`futuraText`}>Vin Diesel</p>
            <p className={`futuraText ${styles.quoteRole}`}>Acteur</p>
          </div>
        </div>
      </div>
      <section className={styles.formPanel}>
        <img className={styles.mainLogo} src={Logo} alt="MotoTour" />

        <div className={styles.formContainer}>
          <Switch>
            <Route
              exact
              path={ROUTES.login.resetPassword}
              component={ResetPassword}
            />
            <Route
              exact
              path={ROUTES.login.forgotPassword}
              component={ForgotPassword}
            />
            <Route
              exact
              path={ROUTES.login.verifyEmail}
              component={VerifyEmail}
            />

            <Route
              exact
              path={ROUTES.login.register}
              component={RegisterForm}
            />
            <Route exact path={ROUTES.login.login} component={LoginForm} />
            <Route render={() => <Redirect to={ROUTES.login.login} />} />
          </Switch>
        </div>

        <footer className={styles.loginFooter}>
          <a className={styles.footerTxt} href="/login">
            Terms of Service
          </a>
          <p className={styles.footerTxt}>MotoTour B.V.</p>
          <a className={styles.footerTxt} href="mailto:contact@mototour.nl">
            contact@mototour.nl
          </a>
        </footer>
      </section>
    </main>
  );
}

export default Login;
