import React from "react";

interface Props {
  icon: string;
  disabled?: boolean;
  onClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

function Button({ icon, disabled = false, onClick }: Props) {
  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className={`iconCTA`}
      disabled={disabled}
      type="button"
    >
      <img src={icon} alt="" />
    </button>
  );
}

export default Button;
