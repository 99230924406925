export const ERRORS = {
  correctEmail: `Ongeldig e-mailadres`,
  password8Char: `Wachtwoord moet minstens 8 karakters lang zijn`,
  passwordMatch: `Wachtwoorden moeten overeenkomen`,
  resBody: `Er werd een response body verwacht, maar werd niet meegeleverd`,
  internalServerError: `Er is iets mis gegaan. Probeer het later opnieuw`,
  unknownError: `Er is iets mis gegaan. Probeer het opnieuw`,
  invalidCredentials: `Email of wachtwoord is incorrect.`,
  serviceUnavailable: `Onze services zijn tijdelijk onbereikbaar. Probeer het later opnieuw`,
  unauthorized: `Enkel MotoTour admins kunnen momenteel inloggen.`,
  empty: `Gelieve dit veld in the vullen.`,
  price: `Gelieve een prijs in te vullen`,
  name: `Gelieve een titel in te vullen`,
  description: `Gelieve een beschrijving in te vullen`,
  duration: `Gelieve een duur in te vullen`,
  distance: `Gelieve een afstand in te vullen`,
};
