import { Authentication, Profile } from "./../../api/index";
import { apiService } from "../../api/service";

import store from "../../store/store";
import {
  setMototourApiCreds,
  resetCredentials,
} from "../../store/state/credentials";

import {
  IVerifyEmail,
  IForgotPassword,
  IResetPassword,
} from "./../../models/authentication/LoginInput";
import ILoginInput from "../../models/authentication/LoginInput";
import { IMainApiCredentials } from "../../models/authentication/ApiCredentials";

export async function loginUser(
  loginInput: ILoginInput,
  rememberUser: boolean
): Promise<void> {
  const credentials: IMainApiCredentials = await apiService(
    Authentication.loginEmail({ body: loginInput })
  );

  if (rememberUser) {
    localStorage.setItem("accessToken", credentials.token.accessToken);
    localStorage.setItem("refreshToken", credentials.token.refreshToken);
    localStorage.setItem("expireDate", credentials.token.expireDate);
  }

  store.dispatch(setMototourApiCreds(credentials));
}

export async function logoutUser() {
  await apiService(Authentication.logout());
  store.dispatch(resetCredentials());
  localStorage.clear();
}

export async function getProfile(): Promise<boolean> {
  const accessToken = localStorage.getItem("accessToken");

  if (accessToken) {
    const credentials = await apiService(Profile.get());
    store.dispatch(setMototourApiCreds(credentials));

    return true;
  }
  return false;
}

export async function resetPassword(
  resetPasswordInput: IResetPassword
): Promise<void> {
  await apiService(Profile.reset({ body: resetPasswordInput }));
}

export async function forgotPassword(
  forgotPasswordInput: IForgotPassword
): Promise<void> {
  await apiService(Profile.forgot({ body: forgotPasswordInput }));
}

export async function verifyEmail(
  verifyEmailInput: IVerifyEmail
): Promise<void> {
  await apiService(Profile.verify({ body: verifyEmailInput }));
}
