export const PLACEHOLDERS = {
  password: `Wachtwoord`,
  passwordNew: `Nieuw wachtwoord`,
  passwordRepeat: `Herhaal wachtwoord`,
  email: `Email`,
  name: `Titel`,
  price: `Prijs`,
  description: `Beschrijving`,
  tags: `Tags`,
  duration: `Duur`,
  distance: `Afstand`,
};
