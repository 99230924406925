import React, { ReactChild } from "react";

import styles from "./RadioButton.module.scss";

interface Props {
  value: number;
  checked: boolean;
  children: ReactChild;
}

function RadioButton({ value, checked, children }: Props) {
  return (
    <label className={styles.radio} htmlFor={`radio${value}`}>
      <input
        className={styles.radioInput}
        type="radio"
        id={`radio${value}`}
        value={value}
        checked={checked}
        readOnly
      />
      <div className={styles.radioButton} />
      {children}
    </label>
  );
}

export default RadioButton;
