import { UserFriendlyError } from "./UserFriendlyErrors";
import { ERRORS } from "./../constants/errors";

export function throwErrorBasedOnStatus(responseStatus: number): void {
  switch (responseStatus) {
    case 401:
      throw new UserFriendlyError(ERRORS.invalidCredentials, responseStatus);
    case 500:
      throw new UserFriendlyError(ERRORS.internalServerError, responseStatus);
    case 503:
      throw new UserFriendlyError(ERRORS.serviceUnavailable, responseStatus);
    default:
      throw new UserFriendlyError(ERRORS.unknownError, responseStatus);
  }
}
