import React, { useState } from "react";

import styles from "./WaypointsListElement.module.scss";
import { IWaypoint } from "../../models/Waypoint";

import WaypointElement from "../../assets/icons/waypointElement.svg";
import Draggable from "../../assets/icons/draggable.svg";

interface Props {
  waypoint: IWaypoint;
  isDragging: boolean;
}

function WaypointsListElement({ waypoint, isDragging }: Props) {
  const [isHovered, setIsHovered] = useState(false);
  const focusStyle = isDragging || isHovered ? styles.focussed : "";

  return (
    <div
      className={styles.waypointElementWrapper}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={styles.drag}>
        <img src={Draggable} alt="Sleepbaar" />
      </div>
      <div className={`${styles.internalWrapper} ${focusStyle}`}>
        <div className={styles.icon}>
          <img src={WaypointElement} alt="Waypoint icon" />
        </div>
        <div className={styles.text}>
          <div className={styles.lat}> Lat: {waypoint.lat.toFixed(4)}</div>
          <div className={styles.lng}> Lon: {waypoint.lng.toFixed(4)}</div>
        </div>
      </div>
    </div>
  );
}

export default WaypointsListElement;
