import { IFilter, IFilters } from "./../../models/Filter";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: IFilters = {
  showFilters: false,
  openFilter: "",
  filters: {},
};

const filterSlice = createSlice({
  name: "routes",
  initialState,
  reducers: {
    setFilter(state: IFilters, action: PayloadAction<IFilter>) {
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      };
    },
    setOpenFilter(state: IFilters, action: PayloadAction<string>) {
      return { ...state, openFilter: action.payload };
    },
    resetFilter(state: IFilters, action: PayloadAction<string | string[]>) {
      const filters = Object.assign({}, state.filters);
      if (Array.isArray(action.payload)) {
        action.payload.forEach((filter) => {
          delete filters[filter];
        });
      } else {
        delete filters[action.payload];
      }

      return { ...state, filters };
    },
    showFilters(state: IFilters, action: PayloadAction<boolean>) {
      return { ...state, showFilters: action.payload };
    },
    resetFilters() {
      return initialState;
    },
  },
});

export const {
  setFilter,
  setOpenFilter,
  resetFilter,
  showFilters,
  resetFilters,
} = filterSlice.actions;
export default filterSlice.reducer;
