import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object } from "yup";

import styles from "./ForgotPassword.module.scss";
import useApi from "../../../hooks/useApi";
import { SCHEMAS } from "../../../constants/schemas";
import { ROUTES } from "../../../constants/routes";
import { PLACEHOLDERS } from "../../../constants/placeholders";

import EmailIcon from "../../../assets/icons/email.png";

import TextField from "../../../components/input/TextField";
import BackButton from "../../../components/navigation/BackButton";
import Button from "../../../components/navigation/Button";
import { forgotPassword } from "../../../api/actions/userActions";
import { IForgotPassword } from "../../../models/authentication/LoginInput";

const schema = object().shape({
  email: SCHEMAS.email,
});

function ForgotPassword() {
  const mounted = useRef(false);
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState<IForgotPassword>({
    email: "",
  });

  const { register, handleSubmit } = useForm<IForgotPassword>({
    resolver: yupResolver(schema),
    defaultValues: values,
  });

  const submitForm = (data: IForgotPassword) => {
    setValues(data);
  };

  const requestForgotPassword = useApi({
    action: () => forgotPassword(values),
    onStart: () => setLoading(true),
    onError: () => setLoading(false),
    onSuccess: () => setSubmit(true),
  });

  useEffect(() => {
    if (mounted.current) requestForgotPassword();
    mounted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <div className={styles.container}>
      <div className={styles.back}>
        <BackButton path={ROUTES.login.login} />
      </div>
      {!submit && (
        <>
          <h1>Wachtwoord vergeten?</h1>
          <p>
            Dat is erg vervelend. Vul hieronder het e-mailadres van je account
            in en dan sturen wij je een link om je wachtwoord opnieuw in te
            stellen.
          </p>
          <TextField
            label="email"
            type="email"
            ref={register}
            placeholder={PLACEHOLDERS.email}
          />
          <Button
            text="Wachtwoord opnieuw instellen"
            type="primary"
            onClick={handleSubmit(submitForm)}
            loading={loading}
          />
        </>
      )}
      {submit && (
        <div className={styles.confirmation}>
          <img src={EmailIcon} alt="E-mail icoon" />
          <p>
            Er is een mail gestuurd naar <b>{values.email}</b> met een link om
            je wachtwoord opnieuw in te stellen
          </p>
        </div>
      )}
    </div>
  );
}

export default ForgotPassword;
