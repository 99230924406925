import React, { useState } from "react";
import { useSelector } from "react-redux";

import styles from "./DropDownTypes.module.scss";
import store from "../../../store/store";
import { RootState } from "../../../store/rootReducer";
import { resetFilter, setFilter } from "../../../store/state/filters";

import Reset from "../../../assets/icons/reset.svg";

import Slider from "../Slider";

function DistanceDropDown() {
  const filters = useSelector((state: RootState) => state.filters.filters);
  const [range] = useState([0, 1000]);
  const minMax = [
    +filters.minDistance || range[0],
    +filters.maxDistance || range[1],
  ];
  const [distance, setDistance] = useState(minMax);

  function commitDistance(value: number[]) {
    store.dispatch(
      setFilter({
        minDistance: value[0].toString(),
        maxDistance: value[1].toString(),
      })
    );
    setDistance(value);
  }

  function resetDistance() {
    store.dispatch(resetFilter(["minDistance", "maxDistance"]));
    setDistance(range);
  }

  return (
    <div className={`${styles.distanceOptions} ${styles.dropdownBox}`}>
      <Slider
        value={distance}
        setValue={setDistance}
        commitValue={commitDistance}
        steps={10}
        range={range}
      />
      <div className={styles.bottom}>
        <span>{`${distance[0]} km - ${distance[1]} km`}</span>
        <img src={Reset} alt="Reset afstand" onClick={resetDistance} />
      </div>
    </div>
  );
}

export default DistanceDropDown;
