import React from "react";

import styles from "./Home.module.css";

import SideMenu from "../sideMenu/SideMenu";
import TopMenu from "../topMenu/TopMenu";
import MapMenu from "../mapMenu/MapMenu";

function Home() {
  return (
    <div className={styles.home}>
      <SideMenu />
      <TopMenu />
      <MapMenu />
    </div>
  );
}

export default Home;
