import React, { useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import { ROUTES } from "../constants/routes";

import PrivateRoute from "./routeGuards/PrivateRoute";
import Home from "./home/Home";
import { getProfile } from "../api/actions/userActions";

function Root() {
  useEffect(() => {
    getProfile();
  }, []);

  return (
    <Router>
      <PrivateRoute adminOnly={true}>
        <Route path={ROUTES.home.privateRoutes} component={Home} />
      </PrivateRoute>
    </Router>
  );
}

export default Root;
