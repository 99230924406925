import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import styles from "./VerifyEmail.module.css";
import useApi from "../../../hooks/useApi";
import { ROUTES } from "../../../constants/routes";

import ErrorIcon from "../../../assets/icons/error.svg";
import SuccessIcon from "../../../assets/icons/verified.svg";

import Loader from "../../../components/loader/Loader";
import BackButton from "../../../components/navigation/BackButton";
import { verifyEmail } from "../../../api/actions/userActions";

function VerifyEmail() {
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const token = new URLSearchParams(useLocation().search).get("token") || "";

  const sendVerification = useApi({
    action: () => verifyEmail({ token }),
    onStart: () => setLoading(true),
    onError: () => {
      setError(true);
      setLoading(false);
    },
    onSuccess: () => {
      setSubmit(true);
      setLoading(false);
    },
  });

  useEffect(() => {
    if (token) {
      sendVerification();
    }
    // eslint-disable-next-line
  }, [token]);

  return (
    <>
      {loading && (
        <div className={styles.loading}>
          <h1>Email verifiëren</h1>
          <Loader />
        </div>
      )}
      {error && (
        <div className={styles.error}>
          <img src={ErrorIcon} alt="Error icon" />
          <h1>Er is iets mis gegaan</h1>
          <p>
            Probeer het later opnieuw of neem contact op via
            <a href="mailto:contact@mototour.nl"> contact@mototour.nl</a>
          </p>
        </div>
      )}
      {submit && (
        <div className={styles.confirmation}>
          <img src={SuccessIcon} alt="Succes icoon" />
          <h1>Je e-mailadres is geverifieerd</h1>
          <p>De eerste credit is binnen om direct te gaan rijden!</p>
        </div>
      )}
      <div className={styles.back}>
        <BackButton path={ROUTES.login.login} />
      </div>
    </>
  );
}

export default VerifyEmail;
