import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import styles from "./DetailRoute.module.scss";
import store from "../../../store/store";
import { RootState } from "../../../store/rootReducer";
import { changeSelectedRoute } from "../../../store/state/selectedRoute";
import { ROUTES } from "../../../constants/routes";

import EditButton from "../../../assets/icons/editButton.svg";

import InfoCard from "../../../components/infoCard/InfoCard";
import SideBarHeader from "../../../components/sideBarHeader/SideBarHeader";
import WaypointsList from "../../../components/waypointsList/WaypointsList";

function DetailRoute() {
  const history = useHistory();

  const route = useSelector((state: RootState) => state.selectedRoute);

  useEffect(() => {
    store.dispatch(changeSelectedRoute({ edit: true }));

    return () => {
      store.dispatch(changeSelectedRoute({ edit: false }));
    };
  }, []);

  return (
    <div className={styles.routeDetailWrapper}>
      <SideBarHeader
        title={route.route.name!}
        reset
        path={ROUTES.home.privateRoutes}
        icon={EditButton}
        onClick={() => history.push(ROUTES.route.edit)}
      />
      <div className={styles.infoCard}>
        <InfoCard route={route.route} />
      </div>
      <div className={styles.waypoints}>
        <WaypointsList route={route} />
      </div>
    </div>
  );
}

export default DetailRoute;
