import React from "react";
import { useSelector } from "react-redux";

import styles from "./DropDown.module.scss";
import store from "../../store/store";
import { RootState } from "../../store/rootReducer";
import { setOpenFilter } from "../../store/state/filters";

import ArrowDown from "../../assets/icons/arrow-down.svg";
import { SORTING } from "../../constants/filters";

interface Props {
  icon?: string;
  text?: string;
  type: string;
  dropdownType: JSX.Element;
}

function DropDown({ icon, text, type, dropdownType }: Props) {
  const filters = useSelector((state: RootState) => state.filters);

  function toggleDropdown() {
    if (filters.openFilter === type) {
      store.dispatch(setOpenFilter(""));
      return;
    }
    store.dispatch(setOpenFilter(type));
  }

  return (
    <div className={styles.dropdownWrapper}>
      <div className={styles.dropdown} onClick={toggleDropdown}>
        <div className={styles.imageWrapper}>
          <img src={ArrowDown} alt="Open dropdown icon" />
          {icon && <img id={styles.left} src={icon} alt={`${text} icon`} />}
        </div>
        <span>{text || SORTING[+filters.filters[type]]}</span>
      </div>
      {filters.openFilter === type ? dropdownType : null}
    </div>
  );
}

export default DropDown;
