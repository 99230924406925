/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from "axios";

import { UserFriendlyError } from "./../errors/UserFriendlyErrors";

export async function apiService(
  apiRequest: Promise<any>,
  returnResponse?: boolean
): Promise<any> {
  return apiRequest
    .then((response: AxiosResponse) => {
      if (returnResponse) {
        return response;
      }
      return response.data;
    })
    .catch((error: UserFriendlyError) => {
      // Display error alert
      throw error;
    });
}
