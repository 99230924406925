import React from "react";
import { useSelector } from "react-redux";

import styles from "./RouteListItem.module.scss";
import store from "../../store/store";
import useApi from "../../hooks/useApi";
import IRoute from "../../models/Route";
import {
  changePreviewRoute,
  resetPreviewRoute,
  setPreviewRoute,
} from "../../store/state/previewRoute";
import { RootState } from "../../store/rootReducer";
import { getRouteWaypoints } from "../../api/actions/routesActions";
import { formatImgUrl } from "../../utils/formatUtils";

import ArrowIcon from "../../assets/icons/arrow.svg";

type Props = {
  route: IRoute;
};

export default function ({ route }: Props) {
  const previewRoute = useSelector((state: RootState) => state.previewRoute);
  const selectedRoute = useSelector((state: RootState) => state.selectedRoute);

  const getRouteWaypointsFromAPI = useApi({
    action: () => getRouteWaypoints(route),
  });

  function getRoute() {
    getRouteWaypointsFromAPI();
  }

  function showPreview() {
    store.dispatch(changePreviewRoute({ route, previewShown: true }));
  }

  function hidePreview() {
    if (route !== selectedRoute.route) {
      if (
        selectedRoute.route !== previewRoute.route &&
        selectedRoute.route.name
      ) {
        store.dispatch(
          setPreviewRoute({ route: selectedRoute.route, previewShown: true })
        );
        return;
      }
      store.dispatch(resetPreviewRoute());
    }
  }

  const isSelected =
    route === previewRoute.route || route === selectedRoute.route;

  function parseTags() {
    if (route.tags === undefined) {
      return <p>Geen tags beschikbaar</p>;
    } else {
      return route.tags.map((tag) => (
        <p className={styles.tag} key={tag._id}>
          {tag.name}
        </p>
      ));
    }
  }

  return (
    <div
      className={`${styles.routeItem} ${isSelected ? styles.selected : ""}`}
      onClick={getRoute}
      onMouseEnter={showPreview}
      onMouseLeave={hidePreview}
    >
      <div className={styles.container}>
        <img
          className={styles.routeImage}
          src={formatImgUrl(route.images)}
          onError={(e) => (e.currentTarget.src = formatImgUrl(undefined))}
          alt={`Een foto genomen uit de route: ${route.name}.`}
        />

        <div className={styles.wrapper}>
          <div className={styles.info}>
            <h3 className={`smallTitle ${styles.title}`}>{route.name}</h3>
            <div className={`subText ${styles.tags}`}>
              <span role="img" aria-label="tag" className={styles.tagImage} />
              {parseTags()}
            </div>
          </div>

          <div className={styles.distance}>
            {isSelected ? (
              <img src={ArrowIcon} alt="Pijl icon" />
            ) : (
              <p className={`subText`}>{`${route.distance} km`}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
