import { ERRORS } from "./errors";
import { string, number, array, boolean } from "yup";

export const SCHEMAS = {
  track: array().required(),
  name: string().required(ERRORS.name),
  description: string().required(ERRORS.description),
  images: array().default([]),
  price: number().default(1).min(0).positive().round("ceil").required(),
  duration: number().min(0).positive().round("ceil"),
  distance: number().min(0).positive().round("ceil"),
  private: boolean().default(true).required(),
  email: string()
    .email(ERRORS.correctEmail)
    .required()
    .transform((value: string) => {
      return value.toLowerCase();
    }),
  password: string().min(8, ERRORS.password8Char).required(),
};
