import React from "react";

import Button from "./Button";

interface Props {
  text: string;
  type: string;
  disabled?: boolean;
  loading?: boolean;
  onClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

function ButtonBox({
  text,
  type,
  disabled = false,
  loading = false,
  onClick,
}: Props) {
  return (
    <div className="buttonBox">
      <Button
        text={text}
        type={type}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        loading={loading}
        disabled={disabled}
      />
    </div>
  );
}

export default ButtonBox;
