import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { ROUTES } from "../../../constants/routes";

import RouteSet from "../../routeGuards/RouteSet";
import CreateRoute from "../createRoute/CreateRoute";
import EditRoute from "../editRoute/EditRoute";
import DetailRoute from "../detailRoute/DetailRoute";

function RouteElement() {
  return (
    <Switch>
      <Route exact path={ROUTES.route.create} component={CreateRoute} />
      <RouteSet>
        <Switch>
          <Route exact path={ROUTES.route.edit} component={EditRoute} />
          <Route path={ROUTES.route.route} component={DetailRoute} />
          <Route render={() => <Redirect to={ROUTES.route.route} />} />
        </Switch>
      </RouteSet>
    </Switch>
  );
}

export default RouteElement;
