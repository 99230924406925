import React from "react";
import { NavLink } from "react-router-dom";

import styles from "./ListSwitcher.module.scss";
import store from "../../store/store";
import { ROUTES } from "../../constants/routes";
import { resetRoute } from "../../api/actions/routesActions";
import { togglePrivate } from "../../store/state/routes";
import { useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import { resetSelectedRoute } from "../../store/state/selectedRoute";
import { resetPreviewRoute } from "../../store/state/previewRoute";

function ListSwitcher() {
  const privateRoutes = useSelector((state: RootState) => state.routes.private);

  return (
    <header className={styles.navHeader}>
      <span
        className={`heavyTitle ${styles.listSwitch} ${
          privateRoutes ? styles.listSwitchActive : ""
        }`}
        onClick={() => {
          store.dispatch(togglePrivate(true));
          store.dispatch(resetSelectedRoute());
          store.dispatch(resetPreviewRoute());
        }}
      >
        Mijn Routes
      </span>
      <span
        className={`heavyTitle ${styles.listSwitch} ${
          privateRoutes ? "" : styles.listSwitchActive
        }`}
        onClick={() => {
          store.dispatch(togglePrivate(false));
          store.dispatch(resetSelectedRoute());
          store.dispatch(resetPreviewRoute());
        }}
      >
        Ontdek Routes
      </span>

      <NavLink
        to={ROUTES.route.create}
        className={`primaryCTA ${styles.createRouteButton}`}
        onClick={resetRoute}
      >
        + Route
      </NavLink>
    </header>
  );
}

export default ListSwitcher;
