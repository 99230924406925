import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import styles from "./ProfileMenu.module.scss";
import { ROUTES } from "../../constants/routes";
import { RootState } from "../../store/rootReducer";

import ProfileImgPlaceholder from "../../assets/icons/profile.svg";
import { logoutUser } from "../../api/actions/userActions";

function ProfileMenu() {
  const history = useHistory();
  const user = useSelector((state: RootState) => state.credentials);

  function logout() {
    logoutUser();
    history.push(ROUTES.login.login);
  }

  return (
    <div className={styles.ProfileMenuWrapper}>
      <div className={styles.text}>
        <h1 className={styles.user}>
          {user.mainApi.name || user.mainApi.email}
        </h1>
        <button className={`tertiaryCTA`} onClick={logout}>
          Log out
        </button>
      </div>
      <img
        src={user.mainApi.profileImageUrl || ProfileImgPlaceholder}
        alt="Uw profielfoto"
        className={styles.ProfileImgPlaceholder}
      />
    </div>
  );
}

export default ProfileMenu;
