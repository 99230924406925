import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, ref } from "yup";

import styles from "./ResetPassword.module.scss";
import useApi from "../../../hooks/useApi";
import { SCHEMAS } from "../../../constants/schemas";
import { ROUTES } from "../../../constants/routes";
import { PLACEHOLDERS } from "../../../constants/placeholders";

import SuccessIcon from "../../../assets/icons/verified.svg";

import TextField from "../../../components/input/TextField";
import BackButton from "../../../components/navigation/BackButton";
import Button from "../../../components/navigation/Button";
import { resetPassword } from "../../../api/actions/userActions";
import {
  IResetPassword,
  IResetPasswordInput,
} from "../../../models/authentication/LoginInput";
import { ERRORS } from "../../../constants/errors";

const schema = object().shape({
  password: SCHEMAS.password,
  confirmPassword: SCHEMAS.password.oneOf(
    [ref("password")],
    ERRORS.passwordMatch
  ),
});

function ResetPassword() {
  const mounted = useRef(false);
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [values, setValues] = React.useState<IResetPassword>({
    password: "",
    confirmPassword: "",
    token: new URLSearchParams(useLocation().search).get("token") || "",
  });

  const { register, handleSubmit } = useForm<IResetPasswordInput>({
    resolver: yupResolver(schema),
    defaultValues: values,
  });

  const submitForm = (data: IResetPasswordInput) => {
    setValues((values) => ({
      ...values,
      password: data.password,
      confirmPassword: data.confirmPassword,
    }));
  };

  const submitPassword = useApi({
    action: () => resetPassword(values),
    onStart: () => setLoading(true),
    onError: () => setLoading(false),
    onSuccess: () => setSubmit(true),
  });

  useEffect(() => {
    if (mounted.current && values.token) submitPassword();
    mounted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <div className={styles.container}>
      <div className={styles.back}>
        <BackButton path={ROUTES.login.login} />
      </div>
      {!submit && (
        <>
          <h1>Nieuw wachtwoord</h1>
          <p>
            Maak een nieuw wachtwoord aan voor je account en je kunt direct weer
            gaan touren!
          </p>
          <TextField
            label="password"
            type="password"
            ref={register}
            placeholder={PLACEHOLDERS.passwordNew}
          />
          <TextField
            label="confirmPassword"
            type="password"
            ref={register}
            placeholder={PLACEHOLDERS.passwordRepeat}
          />
          <Button
            text="Wijzig wachtwoord"
            type="primary"
            onClick={handleSubmit(submitForm)}
            loading={loading}
          />
        </>
      )}
      {submit && (
        <div className={styles.confirmation}>
          <img src={SuccessIcon} alt="Succes icoon" />
          <h1>Je wachtwoord is opnieuw ingesteld</h1>
          <p>
            Je kunt inloggen in de app met je nieuwe wachtwoord. Veel plezier
            met touren!
          </p>
        </div>
      )}
    </div>
  );
}

export default ResetPassword;
