import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import { ROUTES } from "../../constants/routes";
import { RootState } from "../../store/rootReducer";

type Props = {
  children: React.ReactChild;
};

const RouteSet = ({ children }: Props) => {
  const route = useSelector((state: RootState) => state.selectedRoute);

  return (
    <Route
      render={() =>
        route.waypoints.length ? (
          children
        ) : (
          <Redirect to={ROUTES.home.privateRoutes} />
        )
      }
    />
  );
};

export default RouteSet;
