import { get, post, put, patch, destroy, hereAPI } from "./setup";
import IAPI, { IAPIConfig } from "./../models/Api";

function formatRequest({ method, url, data = {}, auth = false }: IAPIConfig) {
  const config = {
    ...data.config,
    params: data.query,
    withCredentials: auth,
  };

  switch (method) {
    case "GET":
      return get(url, config);
    case "POST":
      return post(url, data.body, config);
    case "PUT":
      return put(url, data.body, config);
    case "PATCH":
      return patch(url, data.body, config);
    case "DELETE":
      return destroy(url, config);
  }
}

export const Authentication = {
  loginEmail: (data: IAPI) =>
    formatRequest({ method: "POST", url: "/login/email", data }),
  loginApple: (data: IAPI) =>
    formatRequest({ method: "POST", url: "/login/apple", data }),
  loginFacebook: (data: IAPI) =>
    formatRequest({ method: "POST", url: "/login/facebook", data }),
  register: (data: IAPI) =>
    formatRequest({ method: "POST", url: "/register/email", data }),
  refreshToken: (data: IAPI) =>
    formatRequest({ method: "POST", url: "/token/refresh", data }),
  logout: () => formatRequest({ method: "POST", url: "/logout" }),
};

export const Profile = {
  get: () => formatRequest({ method: "GET", url: "/users/me", auth: true }),
  delete: () =>
    formatRequest({ method: "DELETE", url: "/users/me", auth: true }),
  routes: () =>
    formatRequest({ method: "GET", url: "/users/me/routes", auth: true }),
  verify: (data: IAPI) =>
    formatRequest({ method: "PATCH", url: "/users/email/verify", data }),
  exists: (data: IAPI) =>
    formatRequest({ method: "GET", url: "/users/email/exists", data }),
  forgot: (data: IAPI) =>
    formatRequest({ method: "POST", url: "/users/password/forgot", data }),
  reset: (data: IAPI) =>
    formatRequest({ method: "PUT", url: "/users/password/reset", data }),
};

export const Routes = {
  create: (data: IAPI) =>
    formatRequest({ method: "POST", url: "/routes", data, auth: true }),
  get: (data: IAPI) =>
    formatRequest({ method: "GET", url: "/routes", data, auth: true }),
  getById: (id: string) =>
    formatRequest({ method: "GET", url: `/routes/${id}`, auth: true }),
  getWaypoints: (id: string) =>
    formatRequest({
      method: "GET",
      url: `/routes/${id}/waypoints`,
      auth: true,
    }),
  update: (id: string, data: IAPI) =>
    formatRequest({
      method: "PUT",
      url: `/routes/${id}`,
      data,
      auth: true,
    }),
  updateWaypoints: (id: string, data: IAPI) =>
    formatRequest({
      method: "PATCH",
      url: `/routes/${id}/waypoints`,
      data,
      auth: true,
    }),
};

export const HERE = {
  getWaypointsFromGPX: (data: IAPI) =>
    hereAPI.post("/matchroute.json", data.body, {
      ...data.config,
      params: data.query,
    }),
};
