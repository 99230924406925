import React from "react";

import styles from "./CheckBox.module.scss";

type Props = {
  text: string;
  initialValue?: boolean;
  className?: string;
  onChange?: (checked: boolean) => void;
};

function CheckBox({ text, className, initialValue = false, onChange }: Props) {
  return (
    <div className={`${styles.checkBox} ${className}`}>
      <input
        type="checkbox"
        onChange={(e) => onChange?.(e.target.checked)}
        defaultChecked={initialValue}
        id="toggle"
      />
      <label htmlFor="toggle" className={styles.text}>
        {text}
      </label>
    </div>
  );
}

export default CheckBox;
