import IRoute, {
  ISelectedRoute,
  IUpdateRouteParams,
} from "./../../models/Route";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IWaypoint } from "../../models/Waypoint";

const initialState: ISelectedRoute = {
  route: {
    track: [],
    duration: "0",
    price: "0",
    description: "",
    distance: "0",
    rating: "0",
    private: true,
    images: [],
  },
  waypoints: [],
  edit: false,
};

interface IChangeSelectedRoute {
  route?: IRoute;
  waypoints?: IWaypoint[];
  edit?: boolean;
}

const selectedRouteSlice = createSlice({
  name: "selectedRoute",
  initialState,
  reducers: {
    setSelectedRoute(_state, action: PayloadAction<ISelectedRoute>) {
      return action.payload;
    },
    changeSelectedRoute(state, action: PayloadAction<IChangeSelectedRoute>) {
      return {
        ...state,
        waypoints: action.payload.waypoints || state.waypoints,
        route: action.payload.route || state.route,
        edit: action.payload.edit || state.edit,
      };
    },
    updateRouteParams(state, action: PayloadAction<IUpdateRouteParams>) {
      return {
        ...state,
        route: {
          ...state.route,
          distance: action.payload.distance?.toString() || "0",
          duration: action.payload.duration?.toString() || "0",
        },
      };
    },
    resetSelectedRoute() {
      return initialState;
    },
  },
});

export const {
  setSelectedRoute,
  changeSelectedRoute,
  updateRouteParams,
  resetSelectedRoute,
} = selectedRouteSlice.actions;
export default selectedRouteSlice.reducer;
