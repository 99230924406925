import React, { useState } from "react";
import { useSelector } from "react-redux";

import styles from "./DropDownTypes.module.scss";
import store from "../../../store/store";
import { RootState } from "../../../store/rootReducer";
import { setFilter } from "../../../store/state/filters";

import Star from "../../../assets/icons/star.svg";
import StarFull from "../../../assets/icons/star-full.svg";
import Plus from "../../../assets/icons/plus.svg";

import RadioButton from "../RadioButton";

function RatingDropDown() {
  const filter = useSelector(
    (state: RootState) => state.filters.filters.rating
  );
  const [selectedRating, setSelectedRating] = useState(
    (filter as string) || "0"
  );

  function commitRating(value: string) {
    store.dispatch(setFilter({ rating: value }));
    setSelectedRating(value);
  }

  function generateRatingOptions() {
    const maxStars = 5;
    const ratingOptions = [];

    for (let i = 0; i <= maxStars; i++) {
      ratingOptions.push(
        <div
          className={styles.ratingOption}
          key={i}
          onClick={() => {
            if (selectedRating !== i.toString()) commitRating(i.toString());
          }}
        >
          <RadioButton value={i} checked={selectedRating === i.toString()}>
            {i === 0 ? (
              <span>Geen rating</span>
            ) : (
              <div className={styles.stars}>
                {[...Array(i)].map((_, i) => (
                  <span key={i}>
                    <img src={StarFull} alt="Volle ster" />
                  </span>
                ))}
                {[...Array(maxStars - i)].map((_, i) => (
                  <span key={i}>
                    <img src={Star} alt="Lege ster" />
                  </span>
                ))}

                {i !== maxStars && (
                  <span>
                    <img src={Plus} alt="" />
                  </span>
                )}
              </div>
            )}
          </RadioButton>
        </div>
      );
    }

    return ratingOptions;
  }

  return (
    <div className={`${styles.ratingOptions} ${styles.dropdownBox}`}>
      {generateRatingOptions()}
    </div>
  );
}

export default RatingDropDown;
