import React from "react";

interface Props {
  text: string;
  type: string;
  disabled?: boolean;
  loading?: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

function Button({
  text,
  type,
  disabled = false,
  loading = false,
  onClick,
}: Props) {
  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className={`${type}CTA`}
      disabled={disabled || loading}
      type={type === "primary" ? "submit" : "button"}
    >
      {loading ? "laden..." : text}
    </button>
  );
}

export default Button;
