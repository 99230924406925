import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import styles from "./MapMenu.module.scss";
import store from "../../store/store";
import { ROUTES } from "../../constants/routes";
import { RootState } from "../../store/rootReducer";
import { formatTime } from "../../utils/formatUtils";
import { changePreviewRoute } from "../../store/state/previewRoute";
import Preview, { PreviewItem } from "../../models/Preview";

import Duration from "../../assets/icons/duration.svg";
import Distance from "../../assets/icons/distance.svg";
import Rating from "../../assets/icons/star.svg";

import Map from "./Map";
import PreviewCard from "../../components/previewCard/PreviewCard";
import DropDown from "../../components/input/DropDown";
import DistanceDropDown from "../../components/input/dropdownTypes/DistanceDropDown";
import DurationDropDown from "../../components/input/dropdownTypes/DurationDropDown";
import RatingDropDown from "../../components/input/dropdownTypes/RatingDropDown";

function MapMenu() {
  const [previewIsShown, setPreviewIsShown] = useState(false);
  const previewRoute = useSelector((state: RootState) => state.previewRoute);
  const filters = useSelector((state: RootState) => state.filters);
  const route = previewRoute.route;
  const overlay = false;
  const history = useHistory();

  useEffect(() => {
    setPreviewIsShown(previewRoute.previewShown);
  }, [previewRoute]);

  function editPreview() {
    history.push(ROUTES.route.edit);
    store.dispatch(changePreviewRoute({ previewShown: false }));
  }

  function moreInfo() {
    history.push(ROUTES.route.route);
    store.dispatch(changePreviewRoute({ previewShown: false }));
  }

  function formatPreview(): Preview {
    const item: PreviewItem = {
      list: [
        { icon: Distance, text: `${route.distance} km` },
        { icon: Duration, text: formatTime(route.duration) },
        { icon: Rating, text: route.rating || "-" },
      ],
      title: route.name!,
      subtitle: route.description,
      type: "route",
      image: route.images![0],
    };

    return {
      item: item,
      onClose: () => setPreviewIsShown((shown) => !shown),
      onEdit: editPreview,
      onMoreInfo: moreInfo,
    };
  }

  return (
    <div className={styles.mapMenu}>
      {overlay && <div className={styles.overlay} />}
      {filters.showFilters && (
        <div className={styles.filters}>
          <DropDown
            text="Afstand"
            icon={Distance}
            type="distance"
            dropdownType={<DistanceDropDown />}
          />
          <DropDown
            text="Duur"
            icon={Duration}
            type="duration"
            dropdownType={<DurationDropDown />}
          />
          <DropDown
            text="Waardering"
            icon={Rating}
            type="rating"
            dropdownType={<RatingDropDown />}
          />
        </div>
      )}
      {previewIsShown && route && (
        <div className={styles.preview}>
          <PreviewCard preview={formatPreview()} />
        </div>
      )}
      <Map />
    </div>
  );
}

export default MapMenu;
