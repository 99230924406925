import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object } from "yup";

import styles from "./LoginForm.module.scss";
import useApi from "../../../hooks/useApi";
import { SCHEMAS } from "../../../constants/schemas";
import { ROUTES } from "../../../constants/routes";
import { PLACEHOLDERS } from "../../../constants/placeholders";

import CheckBox from "../../../components/input/CheckBox";
import TextField from "../../../components/input/TextField";
import Button from "../../../components/navigation/Button";
import { loginUser } from "../../../api/actions/userActions";
import ILoginInput from "../../../models/authentication/LoginInput";

const schema = object().shape({
  email: SCHEMAS.email,
  password: SCHEMAS.password,
});

function LoginForm() {
  const mounted = useRef(false);
  const history = useHistory();
  const [rememberUser, setRememberUser] = useState(true);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState<ILoginInput>({
    email: "",
    password: "",
  });

  const { register, handleSubmit } = useForm<ILoginInput>({
    resolver: yupResolver(schema),
    defaultValues: values,
  });

  const submitForm = (data: ILoginInput) => {
    setValues(data);
  };

  const doLogin = useApi({
    action: () => loginUser(values, rememberUser),
    onStart: () => setLoading(true),
    onError: () => setLoading(false),
    onSuccess: () => history.push(ROUTES.home.privateRoutes),
  });

  useEffect(() => {
    if (mounted.current) doLogin();
    mounted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  function rememberCheckClicked(doRemember: boolean) {
    setRememberUser(doRemember);
  }

  return (
    <>
      <div className={styles.loginWelcome}>
        <h2 className={`mediumTitle`}>Welkom!</h2>
        <p>Log in om je MotoTour routes te beheren.</p>
      </div>
      <form className={styles.loginForm}>
        <TextField
          label="email"
          type="email"
          ref={register}
          placeholder={PLACEHOLDERS.email}
        />
        <TextField
          label="password"
          type="password"
          ref={register}
          placeholder={PLACEHOLDERS.password}
        />
        <div className={styles.inline}>
          <CheckBox
            text="Onthoud mij"
            initialValue={rememberUser}
            onChange={rememberCheckClicked}
          />
          <div className={styles.forgotPassword}>
            <Link to={ROUTES.login.forgotPassword}>Wachtwoord vergeten?</Link>
          </div>
        </div>
        <Button
          text="Log in"
          type="primary"
          onClick={handleSubmit(submitForm)}
          loading={loading}
        />
      </form>
      <p className={styles.registerLink}>
        Heb je nog geen account?
        <Link to={ROUTES.login.register}> Registreer hier.</Link>
      </p>
    </>
  );
}

export default LoginForm;
