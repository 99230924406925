import React, { useState } from "react";
import { useSelector } from "react-redux";

import styles from "./DropDownTypes.module.scss";
import store from "../../../store/store";
import { RootState } from "../../../store/rootReducer";
import { resetFilter, setFilter } from "../../../store/state/filters";
import { formatTime } from "../../../utils/formatUtils";

import Reset from "../../../assets/icons/reset.svg";

import Slider from "../Slider";

function DurationDropDown() {
  const filters = useSelector((state: RootState) => state.filters.filters);
  const [range] = useState([30, 600]);
  const minMax = [
    +filters.minDuration || range[0],
    +filters.maxDuration || range[1],
  ];
  const [duration, setDuration] = useState(minMax);

  function commitDuration(value: number[]) {
    store.dispatch(
      setFilter({
        minDuration: value[0].toString(),
        maxDuration: value[1].toString(),
      })
    );
    setDuration(value);
  }

  function resetDuration() {
    store.dispatch(resetFilter(["minDuration", "maxDuration"]));
    setDuration(range);
  }

  return (
    <div className={`${styles.durationOptions} ${styles.dropdownBox}`}>
      <Slider
        value={duration}
        setValue={setDuration}
        commitValue={commitDuration}
        steps={30}
        range={range}
      />
      <div className={styles.bottom}>
        <span>{`${formatTime(duration[0])} - ${formatTime(duration[1])}`}</span>
        <img src={Reset} alt="Reset duur" onClick={resetDuration} />
      </div>
    </div>
  );
}

export default DurationDropDown;
