import React from "react";

import styles from "./SideBarHeader.module.scss";

import BackButton from "../navigation/BackButton";
import IconButton from "../navigation/IconButton";

export interface SideBarHeaderProps {
  title: string;
  reset?: boolean;
  path?: string;
  icon?: string;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

function SideBarHeader({
  title,
  reset,
  path,
  icon,
  onClick,
}: SideBarHeaderProps) {
  return (
    <header className={styles.headerWrapper}>
      <div className={styles.back}>
        <BackButton path={path} reset={reset} />
      </div>
      <h2 className={`mediumTitle ${styles.headerTitle}`}>{title}</h2>
      <div className={styles.icon}>
        {icon && onClick && (
          <IconButton
            icon={icon}
            onClick={(e) => {
              onClick(e);
            }}
          />
        )}
      </div>
    </header>
  );
}

export default SideBarHeader;
