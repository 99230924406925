import React from "react";

import styles from "./InfoCard.module.scss";
import IRoute from "../../models/Route";
import { formatImgUrl, formatTime } from "../../utils/formatUtils";

import Duration from "../../assets/icons/duration.svg";
import Distance from "../../assets/icons/distance.svg";

import RouteInfoList from "../routeInfoList/RouteInfoList";

interface Props {
  route: IRoute;
}

function InfoCard({ route }: Props) {
  return (
    <div className={styles.infoCardWrapper}>
      <div className={styles.box}></div>
      <div className={styles.content}>
        <h1>{route.name}</h1>
        <RouteInfoList
          list={[
            { icon: Distance, text: `${route.distance} km` },
            { icon: Duration, text: formatTime(route.duration) },
          ]}
          columns={1}
          gap={"5px"}
        />
      </div>
      <div className={styles.image}>
        <img
          src={formatImgUrl(route.images)}
          onError={(e) => (e.currentTarget.src = formatImgUrl(undefined))}
          alt={`Een foto genomen uit de route ${route.name}.`}
        />
      </div>
    </div>
  );
}

export default InfoCard;
