/* eslint-disable @typescript-eslint/no-explicit-any */
import routeImagePlaceholder from "../assets/icons/routeImagePlaceholder.svg";

// Formats the image url for an img element. Returns a default image when no image is found
export function formatImgUrl(images: File | File[] | undefined) {
  if (images) {
    if (Array.isArray(images) && images.length > 0) {
      if (images[0].name) {
        return `${URL.createObjectURL(images)}`;
      }
      return `${images[0]}`;
    } else if (!Array.isArray(images)) {
      if (images.name) {
        return `${URL.createObjectURL(images)}`;
      }
      return `${images}`;
    }
  }

  return routeImagePlaceholder;
}

// Formats a presentable time string from a duration in minutes
// EXAMPLE ~ duration = 100, returns "01:40 u"
export function formatTime(duration: string | number) {
  if (typeof duration === "string") {
    duration = parseInt(duration);
  }

  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;

  const hoursZero = hours < 10 ? "0" + hours : hours;
  const minutesZero = minutes < 10 ? "0" + minutes : minutes;

  return `${hoursZero}:${minutesZero} u`;
}

// Formats an object containing all key/value pairs that differentiate in obj2, using obj1 as base value.
// Example ~ obj1 = { a: 10, b: 5, c: 17 } & obj2 = { a: 12, b:5, c:13 }, returns { a: 12, c: 13 }
export function formatDifference(obj1: any, obj2: any) {
  return Object.entries(obj2).reduce((result: any, [key, value]) => {
    if ((typeof obj1[key] === "boolean" || obj1[key]) && obj1[key] !== value) {
      result[key] = value;
    }

    return result;
  }, {});
}

// Formats the provided seconds to full minutes (rounded up)
// Example ~ seconds = 100, returns 2;
export function formatSeconds(seconds: number) {
  return Math.ceil(seconds / 60);
}

// Formats the provided meters to full kilometers (rounded up)
// Example ~ meters = 10200, returns 11;
export function formatDistance(meters: number) {
  return Math.ceil(meters / 1000);
}
