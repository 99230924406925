import React from "react";
import { Route } from "react-router-dom";
import { useSelector } from "react-redux";

import Login from "../login/Login";
import { ERRORS } from "../../constants/errors";
import { RootState } from "../../store/rootReducer";

type Props = {
  adminOnly?: boolean;
  children: React.ReactChild;
};

const PrivateRoute = ({ adminOnly, children }: Props) => {
  const credentials = useSelector((state: RootState) => state.credentials);

  return (
    <Route
      render={() => {
        if (credentials.loggedIn && credentials.mainApi.role === "Admin") {
          return children;
        }
        if (credentials.loggedIn) {
          if (!adminOnly) {
            return children;
          }
          return <div className="unauthorized">{ERRORS.unauthorized}</div>;
        }
        return <Login />;
      }}
    />
  );
};

export default PrivateRoute;
