import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";

import styles from "./SideMenu.module.css";
import { ROUTES } from "../../constants/routes";

import Routes from "./routes/Routes";
import RouteElement from "./routeElement/RouteElement";

function SideMenu() {
  return (
    <div className={styles.sideMenu}>
      <Switch>
        <Route exact path={ROUTES.home.publicRoutes} component={Routes} />
        <Route exact path={ROUTES.home.privateRoutes} component={Routes} />
        <Route path={ROUTES.route.route} component={RouteElement} />
        <Route render={() => <Redirect to={ROUTES.home.privateRoutes} />} />
      </Switch>
    </div>
  );
}

export default withRouter(SideMenu);
