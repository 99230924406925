import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import "./index.css";
import "./styles/colors.scss";
import "./styles/elements.scss";
import "./styles/form.scss";
import "./styles/typo.scss";
import "./styles/normalize.css";

import store from "./store/store";
import { persistor } from "./store/store";

import Root from "./pages/Root";
import Loader from "./components/loader/Loader";

const routes = (
  <Provider store={store}>
    <PersistGate loading={<Loader />} persistor={persistor}>
      <Root />
    </PersistGate>
  </Provider>
);

ReactDOM.render(routes, document.getElementById("root"));
