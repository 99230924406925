import React from "react";
import { useHistory } from "react-router-dom";

import { resetRoute } from "../../api/actions/routesActions";

interface Props {
  path?: string;
  reset?: boolean;
}

function BackButton({ path, reset = false }: Props) {
  const history = useHistory();

  const handleGoBack = () => {
    if (reset) {
      resetRoute();
    }

    if (path) {
      history.push(path);
      return;
    }

    history.goBack();
  };

  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        handleGoBack();
      }}
      className={`backCTA`}
    />
  );
}

export default BackButton;
