import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IMainApiCredentials,
  IApiCredentials,
} from "../../models/authentication/ApiCredentials";

const initialState: IApiCredentials = {
  mainApi: {
    _id: "",
    email: "",
    credits: 0,
    role: "",
    unlockedRoutes: [
      {
        id: "",
      },
    ],
    redeemedCoupons: [
      {
        id: "",
      },
    ],
    token: {
      _id: "",
      __t: "",
      expireDate: "",
      createdAt: "",
      role: "",
      accessToken: "",
      refreshToken: "",
      userId: "",
    },
    __t: "",
    emailVerified: false,
  },
  loggedIn: false,
};

const credentialSlice = createSlice({
  name: "credentials",
  initialState,
  reducers: {
    setMototourApiCreds(
      state: IApiCredentials,
      action: PayloadAction<IMainApiCredentials>
    ) {
      return { ...state, mainApi: action.payload, loggedIn: true };
    },
    resetCredentials() {
      return initialState;
    },
  },
});

export const {
  setMototourApiCreds,
  resetCredentials,
} = credentialSlice.actions;

export default credentialSlice.reducer;
