import { UserFriendlyError } from "../errors/UserFriendlyErrors";
import { ERRORS } from "../constants/errors";

type Props = {
  action: () => Promise<void>;
  onStart?: () => void;
  onError?: (err: UserFriendlyError) => void;
  onSuccess?: () => void;
};

export default function useApi({ action, onStart, onError, onSuccess }: Props) {
  return async (): Promise<void> => {
    onStart && onStart();
    try {
      await action();
      onSuccess && onSuccess();
    } catch (err) {
      onError &&
        onError(
          err instanceof UserFriendlyError
            ? err
            : new UserFriendlyError(ERRORS.internalServerError, 500)
        );
    }
  };
}
