import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { object } from "yup";

import styles from "./CreateRoute.module.scss";
import useApi from "../../../hooks/useApi";
import { ROUTES } from "../../../constants/routes";
import { createRoute, resetRoute } from "../../../api/actions/routesActions";
import { IRouteForm } from "../../../models/Route";

import SideBarHeader from "../../../components/sideBarHeader/SideBarHeader";
import RouteForm from "../../../components/routeForm/RouteForm";
import { SCHEMAS } from "../../../constants/schemas";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/rootReducer";

const schema = object().shape({
  track: SCHEMAS.track,
  name: SCHEMAS.name,
  description: SCHEMAS.description,
  images: SCHEMAS.images,
  price: SCHEMAS.price,
  duration: SCHEMAS.duration,
  distance: SCHEMAS.distance,
});

function CreateRoute() {
  const mounted = useRef(false);
  const history = useHistory();
  const route = useSelector((state: RootState) => state.selectedRoute.route);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState<IRouteForm>({
    track: [],
    name: "",
    price: 1,
    description: "",
    duration: 0,
    distance: 0,
    private: true,
    images: [],
  });

  const handleCreateRoute = useApi({
    action: () => createRoute(values),
    onStart: () => setLoading(true),
    onError: () => setLoading(false),
    onSuccess: () => {
      resetRoute();
      history.push(ROUTES.home.privateRoutes);
    },
  });

  useEffect(() => {
    if (mounted.current) handleCreateRoute();
    mounted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  function submitRoute(data: IRouteForm) {
    const newValues = { ...route, ...data };
    setValues(newValues);
  }

  return (
    <div className={styles.createRouteWrapper}>
      <SideBarHeader
        title="Route aanmaken"
        reset
        path={ROUTES.home.privateRoutes}
      />
      <div className={styles.routeFormWrapper}>
        <RouteForm
          onSubmit={submitRoute}
          defaultValues={values}
          schema={schema}
          loading={loading}
        />
      </div>
    </div>
  );
}

export default CreateRoute;
