import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IRoutes } from "../../models/Route";

const initialState: IRoutes = {
  routes: [],
  routesCount: 0,
  page: 0,
  private: true,
};

const routeSlice = createSlice({
  name: "routes",
  initialState,
  reducers: {
    setRoutes(state: IRoutes, action: PayloadAction<IRoutes>) {
      const allRoutes = [...state.routes, ...action.payload.routes];
      return {
        ...state,
        routes: allRoutes,
        routesCount: action.payload.routesCount,
        page: state.page,
      };
    },
    togglePrivate(state: IRoutes, action: PayloadAction<boolean>) {
      if (state.private === action.payload) return state;
      return { ...initialState, private: action.payload };
    },
    resetRoutes(state: IRoutes) {
      return { ...initialState, private: state.private };
    },
  },
});

export const { setRoutes, togglePrivate, resetRoutes } = routeSlice.actions;
export default routeSlice.reducer;
