import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { object } from "yup";

import styles from "./EditRoute.module.scss";
import useApi from "../../../hooks/useApi";
import { ROUTES } from "../../../constants/routes";
import { SCHEMAS } from "../../../constants/schemas";
import { RootState } from "../../../store/rootReducer";
import { updateRoute } from "../../../api/actions/routesActions";
import { formatDifference } from "../../../utils/formatUtils";
import { IRouteForm } from "../../../models/Route";

import SideBarHeader from "../../../components/sideBarHeader/SideBarHeader";
import RouteForm from "../../../components/routeForm/RouteForm";

const schema = object().shape({
  name: SCHEMAS.name,
  description: SCHEMAS.description,
  images: SCHEMAS.images,
  price: SCHEMAS.price,
  duration: SCHEMAS.duration,
  distance: SCHEMAS.distance,
  private: SCHEMAS.private,
});

function EditRoute() {
  const mounted = useRef(false);
  const history = useHistory();
  const selectedRoute = useSelector((state: RootState) => state.selectedRoute);
  const [loading, setLoading] = useState(false);
  const initialValues: IRouteForm = {
    track: selectedRoute.route.track || [],
    name: selectedRoute.route.name || "",
    price: +selectedRoute.route.price || 1,
    description: selectedRoute.route.description || "",
    duration: +selectedRoute.route.duration || 0,
    distance: +selectedRoute.route.distance || 0,
    private: selectedRoute.route.private,
    images: selectedRoute.route.images || [],
  };
  const [values, setValues] = useState<IRouteForm>(initialValues);

  const handleUpdateRoute = useApi({
    action: () =>
      updateRoute(
        formatDifference(initialValues, values),
        selectedRoute.route._id!
      ),
    onStart: () => setLoading(true),
    onError: () => setLoading(false),
    onSuccess: () => history.push(ROUTES.route.route),
  });

  useEffect(() => {
    if (mounted.current) handleUpdateRoute();
    mounted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  function submitRoute(data: IRouteForm) {
    setValues(data);
  }

  return (
    <div className={styles.editRouteWrapper}>
      <SideBarHeader title="Route bewerken" path={ROUTES.route.route} />
      <div className={styles.routeFormWrapper}>
        <RouteForm
          onSubmit={submitRoute}
          defaultValues={initialValues}
          schema={schema}
          loading={loading}
          edit
        />
      </div>
    </div>
  );
}

export default EditRoute;
