import React, { useEffect, useState, useRef, useCallback } from "react";
import Dropzone, { DropzoneRef } from "react-dropzone";

import styles from "./FileUpload.module.scss";

type Props = {
  className?: string;
  text: string;
  type?: string;
  accept?: string;
  uploadImage?: string;
  uploadedImage?: string;
  multiple?: boolean;
  hasFocus?: boolean;
  uploaded?: boolean;
  onFocus?: () => void;
  sendUploadedData: (acceptedFiles: File[]) => void;
};

function FileUpload({
  className,
  text,
  type = "file",
  accept = "",
  uploadImage,
  uploadedImage,
  multiple = false,
  hasFocus = false,
  uploaded = false,
  onFocus,
  sendUploadedData,
}: Props) {
  const ref = useRef<HTMLInputElement>(null);
  const [uploadRef, setUpload] = useState(uploaded);
  const [uploadedImageRef, setUploadedImage] = useState(uploadedImage);
  const [textRef, setText] = useState(text);
  const dropzoneRef = useRef<DropzoneRef>(null);
  const [shake, setShake] = useState(0);

  useEffect(() => {
    if (hasFocus) {
      ref.current && ref.current.focus();
    }
  }, [ref, hasFocus]);

  function checkFileExtension(acceptedFiles: File[]): boolean {
    const filename = acceptedFiles[0].name;
    let fileExtension = "";
    if (filename.lastIndexOf(".") > 0) {
      fileExtension = filename.substring(
        filename.lastIndexOf(".") + 1,
        filename.length
      );
    }
    if (fileExtension.toLowerCase() === "gpx" && accept === ".gpx") {
      setText(filename);
      setUploadedImage(uploadedImageRef);
      sendUploadedData(acceptedFiles);
      return true;
    }
    if (
      //probably a better way to do this
      filename.toLowerCase().match(/\.(jpg|jpeg|png)$/) &&
      accept === "image/jpeg,image/png"
    ) {
      setUploadedImage(uploadImage);
      sendUploadedData(acceptedFiles);
      return true;
    }
    setShake(1);
    return false;
  }

  const handleFiles = useCallback(
    (acceptedFiles) => {
      if (multiple) {
        sendUploadedData(acceptedFiles);
      } else {
        if (checkFileExtension(acceptedFiles)) {
          setUpload(true);
          return;
        }
      }
    },
    // eslint-disable-next-line
    [multiple, sendUploadedData]
  );

  const handleOnChange = (files: FileList | null) => {
    if (files) {
      const filesList = Array.from(files);
      handleFiles(filesList);
    }
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      handleFiles(acceptedFiles);
    },
    [handleFiles]
  );

  return (
    <Dropzone
      ref={dropzoneRef}
      onDrop={onDrop}
      minSize={0}
      maxSize={5242880}
      multiple={multiple}
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div
          {...getRootProps()}
          className={`${styles.formGroup} ${className}`}
          style={isDragActive ? { background: "rgba(255, 69, 7, 0.05)" } : {}}
        >
          <input
            {...getInputProps()}
            className={styles.formField}
            type={type}
            accept={accept}
            onFocus={onFocus}
            onChange={(e) => handleOnChange(e.target.files)}
          />
          <div className={styles.center}>
            <img
              src={uploadRef ? uploadedImageRef : uploadImage}
              alt="Upload icon"
              className={styles.uploadImage}
              onAnimationEnd={() => setShake(0)}
              data-shake={shake}
            />
            <div className={styles.infoMessage}>
              {isDragActive ? <p>Sleep het bestand hier</p> : <p>{textRef}</p>}
            </div>
          </div>
        </div>
      )}
    </Dropzone>
  );
}

export default FileUpload;
