import React, { useState } from "react";

import styles from "./ImageBox.module.scss";
import { formatImgUrl } from "../../utils/formatUtils";

import deleteImage from "../../assets/icons/delete-image.svg";

type Props = {
  className?: string;
  image: File;
  index: number;
  removeImage: (index: number) => void;
};

export function ImageBox({ className, image, index, removeImage }: Props) {
  const [hover, setHover] = useState(false);

  return (
    <div
      className={`${styles.imageBox} ${className}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <img
        src={formatImgUrl(image)}
        onError={(e) => (e.currentTarget.src = formatImgUrl(undefined))}
        alt={`geüploade foto ${index + 1}`}
        className={styles.image}
      />
      {hover && (
        <img
          src={deleteImage}
          alt="Verwijder afbeelding"
          className={styles.deleteImage}
          onClick={() => removeImage(index)}
        />
      )}
    </div>
  );
}

export default ImageBox;
