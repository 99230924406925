export const ROUTES = {
  home: {
    privateRoutes: `/`,
    publicRoutes: `/routes`,
  },
  login: {
    login: `/login`,
    register: `/login/register`,
    verifyEmail: `/login/verify`,
    forgotPassword: `/login/forgot`,
    resetPassword: `/login/reset`,
  },
  route: {
    route: "/route",
    create: `/route/create`,
    edit: `/route/edit`,
    stops: `/route/stops`,
  },
  stops: `/stops`,
};
