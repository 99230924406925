import React, { forwardRef } from "react";

import styles from "./TextArea.module.scss";

type Props = {
  label: string;
  ref: HTMLTextAreaElement;
  className?: string;
  placeholder?: string;
  rows?: number;
};

const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  ({ label, rows, className, placeholder }, ref) => (
    <div className={`${styles.formGroup} ${className}`}>
      <textarea
        rows={rows}
        ref={ref}
        name={label}
        className={styles.formField}
        placeholder={placeholder}
      />
      <label className={styles.formField_label}>{placeholder}</label>
    </div>
  )
);

TextArea.displayName = "TextArea";

export default TextArea;
