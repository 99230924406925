import React, { forwardRef } from "react";

import styles from "./TextField.module.scss";

type Props = {
  label: string;
  ref: HTMLInputElement;
  className?: string;
  placeholder?: string;
  type?: string;
};

const TextField = forwardRef<HTMLInputElement, Props>(
  ({ label, placeholder, className, type = "text" }, ref) => (
    <div className={`${styles.formGroup} ${className}`}>
      <input
        name={label}
        ref={ref}
        className={styles.formField}
        placeholder={placeholder}
        type={type}
      />
      <label className={styles.formField_label}>{placeholder}</label>
    </div>
  )
);

TextField.displayName = "TextField";

export default TextField;
