import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ObjectSchema } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTypedController } from "@hookform/strictly-typed";

import styles from "./RouteForm.module.scss";
import { getRouteFromGPX } from "../../api/actions/routesActions";
import { PLACEHOLDERS } from "../../constants/placeholders";
import { IRouteForm } from "../../models/Route";

import uploadGpx from "../../assets/icons/upload-gpx-empty.svg";
import uploadedGpx from "../../assets/icons/uploaded-gpx.svg";
import uploadImg from "../../assets/icons/upload-img-empty.svg";

import FileUpload from "../input/FileUpload";
import TextField from "../input/TextField";
import ButtonBox from "../navigation/ButtonBox";
import TextArea from "../input/TextArea";
import ImageBox from "../input/ImageBox";
import { useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";

interface Props {
  onSubmit: (data: IRouteForm) => void;
  defaultValues: IRouteForm;
  schema: ObjectSchema;
  loading?: boolean;
  edit?: boolean;
}

function RouteForm({
  onSubmit,
  defaultValues,
  schema,
  loading = false,
  edit = false,
}: Props) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
  } = useForm<IRouteForm>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const user = useSelector((state: RootState) => state.credentials);
  const [images, setImages] = useState(
    getValues("images") || defaultValues.images || []
  );
  const TypedController = useTypedController<IRouteForm>({ control });

  const submitForm = (data: IRouteForm) => {
    onSubmit(data);
  };

  function getGPXData(uploadData: File[]) {
    setValue("track", uploadData);
    getRouteFromGPX(uploadData[0]);
  }

  function getImagesData(uploadData: File[]) {
    const imagesList = Object.assign([], getValues("images"));
    imagesList.push(...uploadData);
    setValue("images", imagesList);
    setImages(imagesList);
  }

  function removeImage(index: number) {
    const imagesList = Object.assign([], getValues("images"));
    imagesList.splice(index, 1);
    setValue("images", imagesList);
    setImages(imagesList);
  }

  return (
    <form className={styles.routeForm}>
      <div className={styles.formFields}>
        {!edit && (
          <TypedController
            name="track"
            render={() => (
              <FileUpload
                accept=".gpx"
                text="Sleep hier een gpx-bestand"
                uploadImage={uploadGpx}
                uploadedImage={uploadedGpx}
                sendUploadedData={getGPXData}
              />
            )}
          />
        )}
        <TextField
          label="name"
          ref={register}
          placeholder={PLACEHOLDERS.name}
        />
        <TextArea
          label="description"
          ref={register}
          rows={8}
          placeholder={PLACEHOLDERS.description}
        />
        {user.mainApi.role === "Admin" && (
          <>
            <input name="private" type="checkbox" ref={register} />
            Privé
          </>
        )}
        <div className={styles.imageBoxes}>
          <TypedController
            name="images"
            render={() => (
              <FileUpload
                className={styles.imageUpload}
                accept="image/jpeg,image/png"
                text="Upload afbeelding"
                multiple
                uploadImage={uploadImg}
                sendUploadedData={getImagesData}
              />
            )}
          />
          {images &&
            images.map((image, index) => (
              <ImageBox
                image={image}
                key={index}
                index={index}
                removeImage={() => removeImage(index)}
              />
            ))}
        </div>
      </div>
      <div className={styles.submit}>
        <ButtonBox
          text={`Route ${edit ? "opslaan" : "aanmaken"}`}
          onClick={handleSubmit(submitForm)}
          type="primary"
          loading={loading}
        />
      </div>
    </form>
  );
}

export default RouteForm;
