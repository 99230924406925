import React from "react";

import styles from "./TopMenu.module.css";

import ProfileMenu from "../../components/profileMenu/ProfileMenu";

function TopMenu() {
  return (
    <div className={styles.topMenu}>
      {/* Searchbar */}
      <ProfileMenu />
    </div>
  );
}

export default TopMenu;
