import IRoute, { IPreviewRoute } from "./../../models/Route";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: IPreviewRoute = {
  route: {
    track: [],
    duration: "0",
    price: "0",
    description: "",
    distance: "0",
    rating: "0",
    private: true,
    images: [],
  },
  previewShown: false,
};

interface IChangePreviewRoute {
  route?: IRoute;
  previewShown?: boolean;
}

const previewRouteSlice = createSlice({
  name: "previewRoute",
  initialState,
  reducers: {
    setPreviewRoute(_state, action: PayloadAction<IPreviewRoute>) {
      return action.payload;
    },
    changePreviewRoute(state, action: PayloadAction<IChangePreviewRoute>) {
      const isShown: boolean =
        action.payload.previewShown === undefined
          ? state.previewShown
          : action.payload.previewShown;

      return {
        ...state,
        route: action.payload.route || state.route,
        previewShown: isShown,
      };
    },
    resetPreviewRoute() {
      return initialState;
    },
  },
});

export const {
  setPreviewRoute,
  changePreviewRoute,
  resetPreviewRoute,
} = previewRouteSlice.actions;
export default previewRouteSlice.reducer;
